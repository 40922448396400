<template>
  <v-container fluid class="primary">
    <v-row no-gutters style="height: 100vh" justify="center">
      <v-col md="4" align-self="center">
        <v-card class="pa-2 " outlined tile>
          <p class="text-center mt-10">
            <img
              src="@/assets/images/redd-text-logo.svg"
              alt=""
              style="width: 40%"
              class="mx-auto "
            />
          </p>
          <v-card-title>{{ compRewardName }}</v-card-title>
                    <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
             
                PIN Code
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="login"
                  :rules="[rules.required, rules.min]"
                  
                ></v-text-field>
<v-card-subtitle
            >Need a PIN? Please see your office
            administrator.</v-card-subtitle
          >

              
            </v-form>
          </v-card-text>

          <v-card-actions v-if="showActions">
            <v-btn color="primary" block class="px-4 ml-2" @click="validate" :disabled="!compIsValid">
              Redeem
            </v-btn>
            
            
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import moment from "moment";
export default {
  data: () => ({
    valid: true,
    showActions: true,
    showForgotPassword: false,
    showPassword: false,
    username: "",
    password: "",
    usernameRules: [
      v => !!v || "Username is required",
      v => /.+@.+\..+/.test(v) || "Username must be a valid email address"
    ],
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 4 || "Min 4 characters",
      emailMatch: () => `The email and password you entered don't match`
    },
    id: '',
    name: '',
    reward: {}
    // users: this.$store.state.users,
  }),
  computed: {
    compRewardName() {
      if(this.reward && this.reward.dateUsed) {
        return 'INVALID REWARD - ALREADY USED'
      }
      //if(this.reward && this.reward.name === 'drink') {
      //  return 'Free Amazon Drink - Less than 70 THB'      
      //}
      if(this.reward) {
        return this.name;
      }
      return ''
    },
    compIsValid() {
      if(this.reward.dateUsed) {
        return false
      }
      if(this.password && this.password.length >= 4) {
        return true
      }
      return false
    },
      },
  methods: {
    validate() {
      console.log(`validate`)
     let vm = this
     axios.post(vm.$root.urls.api + '/v3/reward/' + vm.id, {
       withCredentials: false,
        pin: vm.password,
        reward: vm.reward
     })
     .then(function (response) {
          vm.showActions = false;
          vm.$toasted.show("Reward successfully redeemed");

     }).catch(function(error) {
          vm.$toasted
            .show("Unable to redeem this reward.", {
              type: "error",
              icon: "mdi-alert-circle"
            })
        })

    },
 getRewardByID() {
   //v3 get booking data
   let vm = this
   axios.get(vm.$root.urls.api + '/v3/reward/' + vm.id, {
       withCredentials: false
     })
     .then(function (response) {
       vm.name = response.data.text.en.name
        vm.reward = response.data
     });
},
      },
mounted: function() {
    // listen for unit id parameter
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getRewardByID();
    }
  }
  // created: function() {
  //   // this.checkToken();
  // }
};
</script>

<style lang="scss">
.version {
  font-size: 10px;
  color: gray;
}
.v-card__title {
  font-weight: bold;
  text-align: center;
  display: block;
}
</style>
